import useAuth from "@/modules/auth/composables/useAuth";
import {Ref, ref} from "vue";
import {v4 as uuid} from "uuid";
import {AccountingCenterForm} from "@/modules/accountingCenters/interfaces";
import store from "@/store";

const {companyId} = useAuth()
const defaultValues = (): AccountingCenterForm => {
    return {
        id: uuid(),
        name: "",
        description: "",
        status: "active",
        parentId: "",
        companyId: companyId.value
    }
}

const accountingCenterForm: Ref<AccountingCenterForm> = ref(defaultValues())

const useAccountingCenter = () => {
    const catalogs = {
        status: [
            {id: 'active', title: 'Activo'},
            {id: 'inactive', title: 'Inactivo'},
        ],
    };
    const clearForm = () => accountingCenterForm.value = defaultValues()

    const createAccoutingCenter = async(accountingCenterForm: AccountingCenterForm) => {
        return await store.dispatch('accountingCenters/createAccountingCenter', accountingCenterForm)
    }

    const updateAccountingCenter = async (AccountingCenterForm: AccountingCenterForm) => {
        return await store.dispatch('accountingCenters/updateAccountingCenter', AccountingCenterForm)
    }

    const getAccountingCenter = async (id: string): Promise<AccountingCenterForm> => {
        const {ok, data} = await store.dispatch('accountingCenters/getAccountingCenter', id)

        return data;
    }


    return {
        accountingCenterForm,
        catalogs,
        clearForm,
        createAccoutingCenter,
        updateAccountingCenter,
        getAccountingCenter
    }
}

export default useAccountingCenter;
