
import GeneralData from "@/modules/accountingCenters/components/GeneralData.vue";
import useAccountingCenter from "@/modules/accountingCenters/composables/useAccountingCenter";
import toastr from "toastr";
import {ref} from "vue";
import router from "@/router";

export default {
    components: {GeneralData},
    setup() {
        const {clearForm, accountingCenterForm, createAccoutingCenter, catalogs} = useAccountingCenter()
        const sending = ref(false)

        clearForm();

        return {
            sending,
            catalogs,
            cancel: () => router.push({name: 'accounting_centers'}),
            onSubmit: async () => {
                try {
                    sending.value = true
                    const {ok, message} = await createAccoutingCenter(accountingCenterForm.value)

                    if (ok) {
                        toastr.success('Su solicitud se ha procesado correctamente.')
                        await router.push({name: 'accounting_centers'})
                    } else {
                        await toastr.error(message)
                    }
                } finally {
                    sending.value = false
                }
            }
        }
    }
}
