
import {onMounted, ref, watch} from "vue";
import useAccountingCenter from "@/modules/accountingCenters/composables/useAccountingCenter";
import usePanel from "@/composables/usePanel";

const {accountingCenterForm} = useAccountingCenter();

export default {
    props: ['catalogs'],
    setup() {
        const {showPanel, togglePanel} = usePanel()

        const name = ref('')
        const description = ref('')
        const status = ref('')

        onMounted(() => {
            name.value = accountingCenterForm.value.name
            description.value = accountingCenterForm.value.description
            status.value = accountingCenterForm.value.status
        })

        watch(name, val => accountingCenterForm.value.name = val)
        watch(description, val => accountingCenterForm.value.description = val)
        watch(status, val => accountingCenterForm.value.status = val)

        return {
            showPanel,
            togglePanel,
            name,
            description,
            status,
            accountingCenterForm
        }
    }
}
